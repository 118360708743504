import React from "react"
import Layout from "../components/layout"
import "../pages/styles/404.css"

const PageNotFound = ({ location }) => (
    <Layout location={location}>
        <div className="pageNotFound"></div>
    </Layout>
)

export default PageNotFound
